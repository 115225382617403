import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LocationPicker from '../../components/LocationPicker'

export const route = "/it/device/create";
export const role = "IT";

function CreateDevice() {
    const [deviceData, setDeviceData] = useState({
        type: '',
        friendlyName: '',
        name: '',
        terminalId: '',
        profileId: '',
        location: '',
        pier: '',
        ip: '',
        isSpare: false
    });

    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();

    const handleChange = (e) => {
        setDeviceData({ ...deviceData, [e.target.name]: e.target.value });
    };

    const handleLocationChange = (latlng) => {
        setDeviceData({ ...deviceData, location: { lat: latlng.lat, lng: latlng.lng } });
    };

    const checkDeviceExists = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/devices/check?name=${encodeURIComponent(deviceData.name)}&ip=${encodeURIComponent(deviceData.ip)}/`);
        const data = await response.json();
        return data.exists;
    };

    const handleSubmit = async (e) => {

        e.preventDefault();

        const exists = await checkDeviceExists();
        if (exists) {
            setErrorMessage('A device with this name or IP already exists.');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/devices/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(deviceData)
            });

            if (!response.ok) {
                throw new Error('Failed to add device');
            }

            alert('Device added successfully!');

            navigate('/it/device/list');
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('Failed to add device due to server error.')
        }
    };

    return (
        <div className="container mt-5">
            <h2 className="mb-4">Add New Device</h2>
            <form onSubmit={handleSubmit}>
                {errorMessage && <p className="Text-danger">{errorMessage}</p>}
                <div className="mb-3">
                    <label htmlFor="type" className="form-label">Type</label>
                    <select name="type" value={deviceData.type} onChange={handleChange} className="form-select">
                        <option value="">Select a Type</option>
                        <option value="Agilysys POS">Agilysys POS</option>
                        <option value="Gatemaster POS">Gatemaster POS</option>
                        <option value="Ticket Reader">Ticket Reader</option>
                        <option value="Kitchen Printer">Kitchen Printer</option>
                        <option value="Sign Player">Sign Player</option>
                        <option value="Access Point">Access Point</option>
                        {/* <option value="Switch">Switch</option> */}
                    </select>
                </div>
                {deviceData.type !== 'Access Point' && (
                    <div className="mb-3">
                        <label htmlFor="friendlyName" className="form-label">Friendly Name</label>
                        <input type="text" className="form-control" name="friendlyName" value={deviceData.friendlyName} onChange={handleChange} placeholder="Friendly Name" />
                    </div>
                )}
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">Name</label>
                    <input type="text" className="form-control" name="name" value={deviceData.name} onChange={handleChange} placeholder="Name" />
                </div>
                {deviceData.type === 'Agilysys POS' && (
                    <>
                        <div className="mb-3">
                            <label htmlFor="terminalId" className="form-label">Terminal ID</label>
                            <input type="text" className="form-control" name="terminalId" value={deviceData.terminalId} onChange={handleChange} placeholder="Terminal ID" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="profileId" className="form-label">Profile ID</label>
                            <input type="text" className="form-control" name="profileId" value={deviceData.profileId} onChange={handleChange} placeholder="Profile ID" />
                        </div>
                    </>
                )}
                <label htmlFor="type" className="form-label">Is Spare</label>
                <select name="isSpare" value={deviceData.isSpare} onChange={handleChange} className="form-select">
                    <option value={false}>False</option>
                    <option value={true}>True</option>
                </select>
                <label htmlFor="type" className="form-label">Pier</label>
                <select name="pier" value={deviceData.pier} onChange={handleChange} className="form-select">
                    <option value="">Select a pier</option>
                    <option value="Mariner's Pier">Mariner's Pier</option>
                    <option value="Surfside Pier">Surfside Pier</option>
                    <option value="Adventure Pier">Adventure Pier</option>
                </select>
                <div className="mb-3">
                    <label htmlFor="location" className="form-label">Location</label>
                    <LocationPicker onLocationSelected={handleLocationChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="ip" className="form-label">IP Address</label>
                    <input type="text" className="form-control" name="ip" value={deviceData.ip} onChange={handleChange} placeholder="IP" />
                </div>
                <button type="submit" className="btn mb-5 btn-primary">Add Device</button>
            </form>
        </div>
    );
}

export default CreateDevice;